export const GAME_MODE_STORAGE_KEY = "game_mode_key";

export const JWT_CASINO_COOKIE_KEY = "casino_jwt_key_76bRf^VdB^&(7F7n8";

export const REAL_PLAY_IFRAME_AUTH_KEY_COOKIES_KEY = "iframe_auth_key_b&^8fFOyBytrsVTUdIYBTUgv";

export const REAL_PLAY_CLOSE_SESSION_TOKEN_COOKIE_KEY = "real_cls_ssn_*&Br67R7^gnhnm)(um,u)(<u0";

export const REAL_PLAY_IFRAME_QUERY_PARAMS_KEY = "iframe_query_params_real_play_B&^er&^&e(n9p8Y98";

export const REAL_PLAY_SESSION_ID_COOKIES_KEY = "session_id_nbV%bfG*&M9hnbtr!Vu4@#byIUN9GO$I2Mn";

export const FUN_PLAY_IFRAME_AUTH_KEY_COOKIES_KEY = "fun_ifr_auth_key_V^de_f*&-W6Rn*&%Tt8#btO*N";

export const FUN_PLAY_IFRAME_QUERY_STRING_COOKIES_KEY = "iframe_query_params_fun_play_nmn876F87oNB";
