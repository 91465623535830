export const GLOBAL_TIMEOUT = 60000; //[ms]

export const REGULATIONS_PATH = "/pdfs/regulations.pdf";

export const DEFAULT_SESSION_DUATION = 24 * 60 * 60 * 1000;

export const GOOGLE_URL = "https://google.com";

export const G2EARN_PLATFORM_URL = "https://g2earn.com/";

export const HOW_TO_PLAY_URL_DESKTOP = "https://www.youtube.com/watch?v=DkqL8DM8sNM";

export const HOW_TO_PLAY_URL_MOBILE = "https://www.youtube.com/watch?v=SSZ7Aw6oWrw";
