import { isProductionStage } from "@library/project/envs";
import { removeCookie } from "./cookies";

const LAST_RESET_KEY = "reset";
const LAST_RESET_VALUE = "r5";

const storageKeys = ["cookiesKeys", "sessionStorageKeys", "localStorageKeys"] as const;

type Args = {
  [K in (typeof storageKeys)[number]]: string[];
};

export const resetStorage = (args: Args) => {
  args.cookiesKeys.forEach((cookieKey) => removeCookie(cookieKey));

  args.sessionStorageKeys.forEach((sesStorageKey) => sessionStorage.removeItem(sesStorageKey));

  args.localStorageKeys.forEach((locStorageKey) => localStorage.removeItem(locStorageKey));
};

export const resetStorageOmitProd = (args: Args) => {
  const lastResetValue = localStorage.getItem(LAST_RESET_KEY);

  if (lastResetValue === LAST_RESET_VALUE || isProductionStage) return;

  resetStorage(args);

  localStorage.setItem(LAST_RESET_KEY, LAST_RESET_VALUE);
};
