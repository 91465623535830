import { SupportedLangs } from "./constants";

import SharedEn from "@locales/shared/en/main.json";
import SharedRo from "@locales/shared/ro/main.json";
import SharedSp from "@locales/shared/sp/main.json";

type LangResources = {
  [x in SupportedLangs]: {
    shared: any;
  };
};

export const resources: LangResources = {
  en: {
    shared: SharedEn
  },
  ro: {
    shared: SharedRo
  },
  sp: {
    shared: SharedSp
  }
};
