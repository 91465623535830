export const langsMap = {
  en: "english",
  sp: "espanol",
  ro: "romanian"
};

export const supportedLangs = ["en", "sp", "ro"] as const;
export type SupportedLangs = (typeof supportedLangs)[number];

export const langsConfig: Record<SupportedLangs, { isDisabled: boolean; isHidden: boolean }> = {
  en: {
    isDisabled: false,
    isHidden: false
  },
  sp: {
    isDisabled: true,
    isHidden: false
  },
  ro: {
    isDisabled: true,
    isHidden: false
  }
};

export const supportedLangsNotHidden = supportedLangs.filter((lng) => !langsConfig[lng].isHidden);
