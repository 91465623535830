export const CAN_WITHDRAW_KEY = "XMQ)(U#C<(Q#TMV*W#(*^";

export const PICKED_NETWORK_KEY_STORAGE_KEY = `user_picked_net_k_b8a7snV*E%Ebditd&^`;

export const IS_ALREADY_SUBSCRIBED_KEY = "is_already_subscribed_key";

export const FIRST_TIME_VISIT_KEY = "casino_visit_key";

export const IS_ADULT_KEY = "casino_is_adult";

export const KNOWS_HOW_TO_PLAY = "casino_knows_how_to_play";
