import { DEFAULT_SESSION_DUATION } from "./constants/constants";
import { AppStage } from "../../integrations/deployment/AppStage";
import { BackendGameSlugs } from "../../integrations/backend/BackendGameSlugs";

export const BACKEND_GAME_SLUG: BackendGameSlugs =
  (process.env.REACT_APP_GAME_SLUG as BackendGameSlugs) ||
  (() => {
    throw new Error("REACT_APP_GAME_SLUG not provided");
  })();

export const APP_STAGE =
  (process.env.REACT_APP_STAGE as AppStage) ||
  (() => {
    throw new Error("REACT_APP_STAGE not provided");
  })();

export const isDevStage = APP_STAGE === "development";
export const isStagingStage = APP_STAGE === "staging";
export const isProductionStage = APP_STAGE === "production";

export const GAME_IFRAME_URL =
  (process.env.REACT_APP_GAME_IFRAME_URL as string) ||
  (() => {
    throw new Error("REACT_APP_GAME_IFRAME_URL not provided");
  })();

export const CASINO_API_URL =
  (process.env.REACT_APP_CASINO_API_URL as string) ||
  (() => {
    throw new Error("REACT_APP_CASINO_API_URL not provided");
  })();

export const WEBSOCKET_CASINO_API_URL = `${CASINO_API_URL}`.replace("http", "ws").replace("https", "ws");

export const SINGLE_SESSION_DURATION_MS =
  Number(process.env.REACT_APP_SINGLE_SESSION_DURATION_MS) || DEFAULT_SESSION_DUATION;

export const AFFILIATION_PANEL_URL =
  process.env.REACT_APP_AFFILIATION_PANEL_URL ||
  (() => {
    throw new Error("REACT_APP_AFFILIATION_PANEL_URL not provided");
  })();

export const SERVICE_NAME =
  process.env.REACT_APP_SERVICE_NAME ||
  (() => {
    throw new Error("REACT_APP_SERVICE_NAME missing");
  })();
